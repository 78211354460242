import React from "react"

import PdfPreview from "../components/pdf-preview/pdf-preview"
import * as pdfData from "../../static/regulamin.pdf"

class RegulaminPage extends React.Component {

  render() {
    return (
      <PdfPreview pdfData={pdfData}></PdfPreview>
    )
  }
}

export default RegulaminPage
